@import "shared";

@font-face {
  font-family: "MinecraftTen";
  src: url("/fonts/MinecraftTen.ttf");
  font-weight: 500;
  font-style: normal;
}

%base {
  @extend %fontDecorators;
  font-style: normal;
  margin: 0;
}

.brand {
  @extend %base;
  font-family: "MinecraftTen", sans-serif;
  font-weight: 500;
  font-size: 2em;
  line-height: 1.5rem;
  letter-spacing: -0.06rem;
  padding-right: 1px; // fixes gradient clipping
}
