@use "styles/colors";
@use "styles/media";

.editProfileForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 25vw;
  gap: 0.5rem;

  @include media.mobile {
    min-width: 75vw;
  }

  .avatarContainer {
    .avatarInput {
      display: flex;
      margin-top: 10px;

      @include media.mobile {
        flex-direction: column;
        align-items: center;
      }

      .inputContent {
        display: flex;
        flex-direction: column;
        margin-left: 16px;

        @include media.mobile {
          width: 100%;
          margin-left: 0;
        }

      }
    }
  }

  .button {
    margin-top: 1.5rem;
    font-family: "Poppins";
    font-size: 1rem;
    font-weight: 700;
  }
}
