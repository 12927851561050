@use "styles/colors";
@use "styles/media";

.userImage {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.userCardWrapper {
  z-index: 50;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  @include media.mobile {
    z-index: auto;
    position: relative;
    width: 100%;
    height: auto;
    display: block;
  }
}

.userCardContainer {
  padding: 1.75rem 1.75rem 1.5rem 1.35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 3rem;
  right: -12px;
  background-image: url("/images/menu.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0.75rem;

  @include media.mobile {
    width: 200px;
  }

  .userHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 144px;
    position: relative;

    .imageFilter {
      width: 100%;
      height: 100%;
      backdrop-filter: blur(4.5px);
      object-fit: cover;
    }

    .userInfo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px;

      p:first-of-type {
        margin-top: 20px;
      }
    }
  }

  .actionsContainer {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    @include media.mobile {
      width: 100%;
    }

    .profileItem, .logoutItem {
      width: 100%;
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      background: var(--accent);
      border-radius: 0.75rem;
      font-weight: 700;

      .icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
